import * as React from "react"
import styled from 'styled-components';
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

//Components
import ProjectInfo from '../../components/ProjectInfo';
import ProjectHeader from '../../components/ProjectHeader';
import ProjectSubHeaderBlank from "../../components/ProjectSubHeaderBlank";
import FooterWithPadding from '../../components/FooterWithPadding';


const CalendarCentralPage = () => {

  const data = useStaticQuery(graphql`
  query {

    ccIosOne: file(relativePath: { eq: "calendar-central/cc-ios-1.png" }) {
      childImageSharp {
        fluid(maxWidth: 275) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ccIosTwo: file(relativePath: { eq: "calendar-central/cc-ios-2.png" }) {
      childImageSharp {
        fluid(maxWidth: 275) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ccIosThree: file(relativePath: { eq: "calendar-central/cc-ios-3.png" }) {
      childImageSharp {
        fluid(maxWidth: 275) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ccIosFour: file(relativePath: { eq: "calendar-central/cc-ios-4.png" }) {
      childImageSharp {
        fluid(maxWidth: 275) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ccIosFive: file(relativePath: { eq: "calendar-central/cc-ios-5.png" }) {
      childImageSharp {
        fluid(maxWidth: 275) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ccIosSix: file(relativePath: { eq: "calendar-central/cc-ios-6.png" }) {
      childImageSharp {
        fluid(maxWidth: 275) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ccIosSeven: file(relativePath: { eq: "calendar-central/cc-ios-7.png" }) {
      childImageSharp {
        fluid(maxWidth: 275) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ccIosEight: file(relativePath: { eq: "calendar-central/cc-ios-8.png" }) {
      childImageSharp {
        fluid(maxWidth: 275) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ccIosNine: file(relativePath: { eq: "calendar-central/cc-ios-9.png" }) {
      childImageSharp {
        fluid(maxWidth: 275) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ccIosTen: file(relativePath: { eq: "calendar-central/cc-ios-10.png" }) {
      childImageSharp {
        fluid(maxWidth: 275) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ccIosEleven: file(relativePath: { eq: "calendar-central/cc-ios-11.png" }) {
      childImageSharp {
        fluid(maxWidth: 275) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ccIosTwelve: file(relativePath: { eq: "calendar-central/cc-ios-12.png" }) {
      childImageSharp {
        fluid(maxWidth: 275) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ccSketch: file(relativePath: { eq: "calendar-central/calendar-central-sketch.png" }) {
      childImageSharp {
        fluid(maxWidth: 2320) {
          ...GatsbyImageSharpFluid
        }
      }
    }



  }
`)
  return (
    <Grid>
      <ProjectHeader 
        title="Calendar Central"
        subTitle="Tracking resident Attendance and Wellness"
      />
      <ProjectInfo 
        when="2016 - 2018"
        platform="Mobile (iOS)"
        team="VP of Product, 1 PM, 1 Designer"
        role="Lead Designer"
        tools="Sketch, Invision, Abstract, Framer, Protopie, Jira, Confluence, Heap Analytics"

        symbolPlatform="📱"
      />

      <div className="company-info">
        <span>Calendar Central</span> allows senior living community workers to quickly take
        event attendance, add any details of resident participation and instantly share 
        with families.
      </div>

      <div className="background">
        <p className="background-title">Background</p>
        <p className="background-text">
        I worked on this app from its conception to its first launch to production. 
        I collaborated with my product team and senior community workers to solve 
        one of their biggest pain points, taking mandatory resident attendance on paper. 
        The main task to achieve for this app was digitizing a very time consuming and 
        outdated process so workers can get back to spending more time with seniors. 
        I was the lead for this project, so I worked on everything from sketches and 
        wireframes to high-fidelity UI/UX designs and interactions.
        </p>
      </div>

      <div className="background">
        <p className="background-title">Highlights</p>
        <p className="background-text">
          <span className="star">⭐️</span><span>-</span>
            First of its kind, no other solution existed on the market so we had the pressure
            and excitement of building 
            the first mobile solution.
        </p>
        <p className="background-text">
          <span className="star">⭐️</span><span>-</span>
            Rapidly created prototypes for user testing attendance interactions. Iterated and changed weekly for testing in house 
            and at senior living communities.
        </p>
        <p className="background-text">
          <span className="star">⭐️</span><span>-</span>
            Participated in user testing with senior living staff members. Did this until our team felt 
            we had perfected every issue for MVP. 
        </p>
        <p className="background-text">
          <span className="star">⭐️</span><span>-</span>
            Traveled to senior living expo and demoed the app at our booth. 
        </p>
      </div>



      <ProjectSubHeaderBlank 
        title="Selected Designs"
      />
      <ProjectShowcaseCard>
        <Images fluid={data.ccIosOne.childImageSharp.fluid} />
        <Images fluid={data.ccIosTwo.childImageSharp.fluid} />
        <Images fluid={data.ccIosThree.childImageSharp.fluid} />
      </ProjectShowcaseCard>
      <ProjectShowcaseCard>
        <Images fluid={data.ccIosFour.childImageSharp.fluid} />
        <Images fluid={data.ccIosFive.childImageSharp.fluid} />
        <Images fluid={data.ccIosSix.childImageSharp.fluid} />
      </ProjectShowcaseCard>
      <ProjectShowcaseCard>
        <Images fluid={data.ccIosSeven.childImageSharp.fluid} />
        <Images fluid={data.ccIosEight.childImageSharp.fluid} />
        <Images fluid={data.ccIosNine.childImageSharp.fluid} />
      </ProjectShowcaseCard>
      <ProjectShowcaseCard>
        <Images fluid={data.ccIosTen.childImageSharp.fluid} />
        <Images fluid={data.ccIosEleven.childImageSharp.fluid} />
        <Images fluid={data.ccIosTwelve.childImageSharp.fluid} />
      </ProjectShowcaseCard>
      <ProjectShowcaseCardTwo>
        <Images fluid={data.ccSketch.childImageSharp.fluid} />
      </ProjectShowcaseCardTwo>

    <FooterWithPadding />
    </Grid>
  )
}

export default CalendarCentralPage




const Grid = styled.div`
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  max-width: 1110px;
  margin: 0 auto;

  .company-info {
    font-weight: 400;
    font-size: clamp( 16px,2vw,18px);
    line-height: 1.6;
    margin: 32px 0px;
    padding: 0px 0px 0px 11px;
    border-left: 3px solid var(--black-blue);
    color: var(--black-blue);
    span {
      font-weight: bold;
    }
    @media (max-width: 1200px) {
      padding-left: 48px;
      padding-right: 48px;
    }
    @media (max-width: 850px) {
      padding-left: 48px;
      padding-right: 48px;
    }
    @media (max-width: 650px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  @media (max-width: 650px) {
      p .star {
      display: none;
    }
  }

  .background {
    .background-title {
      font-weight: 600;
      font-size: clamp( 16px,2vw,18px);
      padding: 0px 0px 16px 0px;
      color: #0c0c1d;
    }
    .background-text {
      font-weight: 400;
      font-size: clamp( 16px,2vw,18px);
      line-height: 1.6;
      color: var(--off-black);
			padding-bottom: 2.5%;
      span {
        font-weight: 900;
      }
    }
    p .star {
      padding-right: 1%;
    }
    p span {
      font-weight: 900;
      padding-right: .5%;
    }
    @media (max-width: 1200px) {
      padding-left: 48px;
      padding-right: 48px;
    }
    @media (max-width: 850px) {
      padding-left: 48px;
      padding-right: 48px;
    }
    @media (max-width: 650px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
`

const ProjectShowcaseCard = styled.div`
  border: .1rem solid #c7c7cc;
  box-shadow: 0 0.2rem 0.6rem 0 rgba(37,37,37,0.1);
  border-radius: 1rem;
  background: rgba(221, 221, 221, 0.11);
  padding: 48px;
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 12%;

  @media (max-width: 900px) {
    padding: 24px;
  }
  @media (max-width: 1200px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`

const ProjectShowcaseCardTwo = styled.div`
  border: .1rem solid #c7c7cc;
  box-shadow: 0 0.2rem 0.6rem 0 rgba(37,37,37,0.1);
  border-radius: 1rem;
  background: rgba(221, 221, 221, 0.11);
  padding: 48px;
  margin-top: 24px;
  display: grid;

  grid-gap: 12%;

  @media (max-width: 900px) {
    padding: 24px;
  }
  @media (max-width: 1200px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`

const Images = styled(Img)`
  box-shadow: 0px 4px 12px 3px rgba(0, 0, 0, 0.15);
  border-radius: .5rem;
`